
import {defineComponent, ref, getCurrentInstance} from 'vue';
import { IonSlides, IonSlide } from '@ionic/vue';
import PersonaTopicCard from '@/components/topic-card/TopicCard.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

import SwiperCore, {Scrollbar} from 'swiper';
SwiperCore.use([Scrollbar]);

import 'swiper/swiper-bundle.min.css';

export default defineComponent ({
    name: 'TopicSlides',
    components: {
        IonSlides,
        IonSlide,
        Swiper, SwiperSlide,
        PersonaTopicCard
    },
    props: {
        topics: {
            type: Array
        },
        asSkeleton: {
            type: Boolean,
            default: false
        }
    },

    setup()
    {
        const instance = getCurrentInstance();

        const visible = ref(false);

        const slides = ref();
        const setSwiperInstance = (swiper: any) => {
            slides.value = swiper;
        }

        const onSlideChange = () => {
            // console.log('slide change');
        };

        const pagination = false;

        const scrollbar = {
            hide: false
        }

        const t = setTimeout(() => {
            visible.value = !visible.value;
            instance!.proxy!.$forceUpdate();

            if (slides.value)
            {
                slides.value.update()
            }
            clearTimeout(t);
        }, 1);

        return { setSwiperInstance, onSlideChange, pagination, scrollbar, visible};
    }
});
