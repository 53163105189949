
import {defineComponent, ref} from 'vue';
import PersonaHeader from '@/components/header/Header.vue';
import PersonaTopicSlides from '@/components/topic-slides/TopicSlides.vue';
import PersonaError from '@/components/error/Error.vue';
import {IonRow, IonCol} from '@ionic/vue';
import ContentfulService from '@/services/contentful.service';
import MenuService from '@/services/menu.service';
import {useRoute} from 'vue-router';
import useEmitter from '@/composables/useEmitter';
import {EVENTS} from '@/models/app.model';

export default defineComponent( {
    name: 'Dashboard',
    components: {
        PersonaTopicSlides,
        PersonaError,
        PersonaHeader,
        IonRow,
        IonCol
    },
    async setup()
    {
        const route = useRoute();
        const isRedirectFromLogin = route!.redirectedFrom ? route!.redirectedFrom.name === 'Login' : false;

        const error = ref(null);
        const dashboard = ref(null);
        const app = ref(null);
        const isLoading = ref(true);

        try{
            if (isRedirectFromLogin)
            {
                console.log('[Dashboard] isRedirectFromLogin', isRedirectFromLogin);

                isLoading.value = true;

                // after login it take some seconds to get the access token, so we need to wait until the token is ready
                const emitter = useEmitter();
                emitter.on(EVENTS.ACCESS_TOKEN_READY, async () => {
                    const data = await ContentfulService.getData();
                    if (data)
                    {
                        app!.value = data.app;
                        dashboard!.value = data.dashboard;
                    }

                    isLoading.value = false;
                })
            }
            else{
                const data = await ContentfulService.getData();
                if (data)
                {
                    app!.value = data.app;
                    dashboard!.value = data.dashboard;
                }

                isLoading.value = false;
            }

        } catch (e){
            error.value = e;
            console.log('[Dashboard] error', e);
        }

        const onReload = () => {
            MenuService.reload();
        }

        return { error, app, dashboard, onReload, isLoading };
    }
})
