
import {defineComponent, onErrorCaptured, ref} from 'vue';
import PersonaBaseLayout from '@/components/layout/base/BaseLayout.vue';
import PersonaDashboard from '@/components/dashboard/Dashboard.vue';
import PersonaError from '@/components/error/Error.vue';
import {IonRow, IonCol, IonPage, onIonViewWillEnter} from '@ionic/vue';
import PersonaHeader from '@/components/header/Header.vue';
import PersonaTopicSlides from '@/components/topic-slides/TopicSlides.vue';
import ContentfulService from '@/services/contentful.service';
import {useRoute} from 'vue-router';
import { pageview } from 'vue-gtag'

export default defineComponent({
    name: 'DashboardPage',
    components: {
        IonPage,
        PersonaBaseLayout,
        PersonaDashboard,
        PersonaError,
        IonRow,
        IonCol,
        PersonaHeader,
        PersonaTopicSlides
    },
    setup()
    {
        const dashboard = ref(null);
        const app = ref(null);
        const error = ref(null);
        const hide = ref(true);

        const route = useRoute();
        const isRedirectFromLogin = route!.redirectedFrom ? route!.redirectedFrom.name === 'Login' : false;

        onIonViewWillEnter(async () => {
            // this will update the content each time the user opens the dashboard
            // not needed if user has been redirected from Login
            if (!isRedirectFromLogin)
            {
                // console.log('[DashboardPage] Load Dashboard and App');
                try{
                    await ContentfulService.getData();
                } catch (e){
                    error.value = e;
                    console.log('[Dashboard] error', e);
                }
            }
        });

        const t = setTimeout(() => {
                hide.value = false;
                clearTimeout(t);
            }, 100
        );

        onErrorCaptured(e => {
            // @ts-ignore
            error.value = e.message;
        });

        return { error, dashboard, app, hide };
    }
});
