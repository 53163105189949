import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "persona__topic-card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "ion-text-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_skeleton_card = _resolveComponent("persona-skeleton-card")!
  const _component_persona_asset = _resolveComponent("persona-asset")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_persona_icon = _resolveComponent("persona-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.asSkeleton)
      ? (_openBlock(), _createBlock(_component_persona_skeleton_card, { key: 0 }))
      : _createCommentVNode("", true),
    (!_ctx.asSkeleton)
      ? (_openBlock(), _createBlock(_component_ion_card, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openRoute(_ctx.topic)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_persona_asset, {
              "fade-in": true,
              asset: _ctx.topic.cover,
              width: 600,
              "as-background": true
            }, null, 8, ["asset"]),
            _createVNode(_component_ion_card_header, { class: "ion-text-start 2ion-margin-top" }, {
              default: _withCtx(() => [
                (_ctx.topic.subTitle)
                  ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                      key: 0,
                      class: "ion-margin-bottom"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.topic.subTitle), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.topic.title || _ctx.topic.titleHtml)
                  ? (_openBlock(), _createBlock(_component_ion_card_title, {
                      key: 1,
                      class: ""
                    }, {
                      default: _withCtx(() => [
                        (_ctx.topic.titleHyphen)
                          ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.topic.titleHyphen), 1))
                          : _createCommentVNode("", true),
                        (!_ctx.topic.titleHyphen)
                          ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.topic.title), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                (_ctx.topic.text)
                  ? (_openBlock(), _createBlock("span", _hoisted_4, _toDisplayString(_ctx.topic.text), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_persona_icon, {
                  name: "arrow-right",
                  class: "ion-text-end"
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}