
import {defineComponent, toRefs} from 'vue';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from '@ionic/vue';
import PersonaIcon from '@/components/icon/Icon.vue';
import PersonaAsset from '@/components/asset/Asset.vue';
import PersonaSkeletonCard from '@/components/skeleton/skeleton-card/SkeletonCard.vue';
import {Topic} from '@/models/topic.model';
import SessionService from '@/services/session.service';
import {useRouter} from 'vue-router';
import UtilityService from '@/services/utility.service';

export default defineComponent ({
    name: 'TopicCard',
    components: {
        IonCard,
        IonCardHeader,
        IonCardTitle,
        IonCardSubtitle,
        IonCardContent,
        PersonaIcon,
        PersonaAsset,
        PersonaSkeletonCard
    },
    props: {
        topic: {
            type: Topic
        },
        asSkeleton: {
            type: Boolean,
            default: false
        }
    },
    setup(props)
    {
        const { topic } = toRefs(props);

        // console.log('[TopicCard] topic', topic.value)

        if (topic.value)
        {
            UtilityService.hyphen(topic.value.title).then((title) => {
                // @ts-ignore
                topic.value.title = title;
                // @ts-ignore
                topic.value.titleHyphen = title;
            });
        }


        const router = useRouter();
        const openRoute = (topic) => {
            if (topic.path)
            {
                SessionService.setCurrentTopic(topic);
                router.push({path: '/topics/' + topic.path});
            }
        }

        return { openRoute };
    }
});
