import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "persona__topic-slides"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_persona_topic_card = _resolveComponent("persona-topic-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_ctx.visible && _ctx.topics && _ctx.topics.length)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_swiper, {
          "slides-per-view": 4,
          speed: 400,
          pagination: _ctx.asSkeleton ? false : _ctx.pagination,
          scrollbar: _ctx.asSkeleton ? false : _ctx.scrollbar,
          loop: false,
          onSwiper: _ctx.setSwiperInstance,
          onSlideChange: _ctx.onSlideChange
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.topics, (topic) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: topic.id,
                class: "swiper-slide__sm"
              }, {
                default: _withCtx(() => [
                  (!_ctx.asSkeleton)
                    ? (_openBlock(), _createBlock(_component_persona_topic_card, {
                        key: 0,
                        topic: topic
                      }, null, 8, ["topic"]))
                    : _createCommentVNode("", true),
                  (_ctx.asSkeleton)
                    ? (_openBlock(), _createBlock(_component_persona_topic_card, {
                        key: 1,
                        "as-skeleton": _ctx.asSkeleton
                      }, null, 8, ["as-skeleton"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["pagination", "scrollbar", "onSwiper", "onSlideChange"])
      ]))
    : _createCommentVNode("", true)
}